const content = {
    helmet: {
        title: "Web Development Plans: Customized and Affordable Solutions",
        description:
            "Discover our affordable web development and web design plans for your business at RappidTech. Customized websites for professional, institutional sites tailored to your needs. Hire our web development service with our web design agency.",
        canonical: "https://rappidtech.com/plans",
        language: "en",
        author: "RappidTech",
        robots: "index, follow",
        publisher: "RappidTech",
    },
    bigTitle: "Your vision, our creation: high-impact development and design",
    spanBigTitle: "high impact",
    titleSection: "Our Plans",
    plans: [
        {
            title: "Basic Plan",
            button: "Hire Plan",
            description: "Ideal for small and medium-sized projects",
            price: "AR$ 97,000",
            features: [
                {
                    title: "Static site",
                    description:
                        "A static website shows the same content to all users. This content does not change unless the HTML code is manually edited and published by the developer or site administrator.",
                },
                {
                    title: "Up to 3 pages (with 4 sections)",
                    description: "",
                },
                {
                    title: "Contact form",
                    description: "",
                },
                {
                    title: "Whatsapp icon",
                    description: "",
                },
                {
                    title: "Ready in 5 days",
                    description: "",
                },
            ],
            maintenance: {
                title: "Also hire our monthly service",
                features: ["Domain included", "Hosting", "Technical Support"],
                price: "AR$ 15,000 / month",
            },
        },
        {
            title: "Rappid Plan",
            button: "Hire Plan",
            description: "For medium and large projects",
            price: "AR$ 184,000",
            features: [
                {
                    title: "Dynamic site",
                    description:
                        "Interactive and updatable content: dynamic websites can display different content to users. They offer advanced interactivity, including the ability to allow users to create accounts, post comments, and customize content or preferences. For example: online stores, forums, and news portals.",
                },
                {
                    title: "Up to 5 pages (with 6 sections)",
                    description: "",
                },
                {
                    title: "Contact form",
                    description: "",
                },
                {
                    title: "Whatsapp icon",
                    description: "",
                },
                {
                    title: "Ready in 5 days",
                    description: "",
                },
            ],
            maintenance: {
                title: "Also hire our monthly service",
                features: ["Domain included", "Hosting", "Technical Support"],
                price: "AR$ 25,000 / month",
            },
        },
        {
            title: "Custom Plan",
            button: "Request a quote",
            description: "For large and corporate projects",
            price: "",
            features: [
                {
                    title: "Fully customized site tailored to your need",
                    description:
                        "Fully customized webpage, tailor-made, with the functionalities, animations, and content you need to achieve your brand's objective.",
                },
            ],
            maintenance: {
                title: "Also hire our monthly service",
                features: [
                    "Domain included",
                    "Hosting",
                    "Technical Support",
                    "Web analytics service",
                ],
                price: "",
            },
        },
    ],
    faqs: {
        title: "Frequently asked questions about web design and development services",
        faqs: [
            {
                title: "What web design services do you offer?",
                description:
                    "At RappidTech, we offer a wide range of web design services, including UX/UI design, responsive web development, custom website creation, and more. Our team of experts will work with you to create a website that reflects your brand essence and engages your audience.",
            },
            {
                title: "How can I start a web development project with you?",
                description:
                    "Starting a web development project with us is easy. Simply contact us through our contact form or by phone, and we'll be happy to discuss your needs and provide you with a free initial consultation.",
            },
            {
                title: "What advantages does digital process automation offer for my business?",
                description:
                    "Digital process automation can offer numerous advantages for your business, such as workflow optimization, error reduction, time and resource savings, and improved operational efficiency. At RappidTech, we offer customized automation solutions to fit your specific needs.",
            },
            {
                title: "What is the web development process you follow?",
                description:
                    "At RappidTech, we follow a comprehensive and transparent web development process that includes initial consultations, conceptual design, development, testing, and deployment. We work closely with you at every stage to ensure that your needs and goals are successfully met.",
            },
            {
                title: "How can I ensure that my website is secure and protected against cyber threats?",
                description:
                    "The security of your website is a top priority for us. We implement the latest security measures, such as data encryption, SSL certificates, and protection against malware, to ensure the security of your site and your users' information.",
            },
            {
                title: "Do you offer maintenance and support services after the launch of my website?",
                description:
                    "Yes, at RappidTech, we offer ongoing maintenance and support services after the launch of your website. This includes regular updates, security monitoring, technical support, and more to ensure that your site runs optimally and securely in the long term.",
            },
            {
                title: "Can you help me integrate my website with other platforms or external services?",
                description:
                    "Yes, our web development team can help you integrate your website with other platforms and external services, such as payment systems, marketing tools, social media, and more. This allows you to take full advantage of the additional features and functionalities offered by these platforms.",
            },
        ],
    },
};

export default content;
