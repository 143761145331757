const content = {
    helmet: {
        title: "Planes de Desarrollo Web: Soluciones Personalizadas y Económicas",
        description:
            "Descubre nuestros planes economicos de desarrollo web y diseño web para tu negocio en RappidTech. Paginas web personalizadas para sitios web profesionales, institucionales adaptadas a tus necesidades. Contrata nuestro servicio de desarrollo web con nuestra agencia de diseño web",
        canonical: "https://rappidtech.com/planes",
        language: "es",
        autor: "RappidTech",
        robots: "index, follow",
        publisher: "RappidTech",
    },
    bigTitle:
        "Descubre nuestros planes de diseño y desarrollo web adaptados a tus necesidades,",
    spanBigTitle: "ideales para impulsar tu negocio y presencia online",
    titleSection: "Nuestros planes de desarrollo web",
    plans: [
        {
            title: "Pagina web basica",
            button: "Contratar plan",
            description: "Ideal para quienes dan sus primeros pasos en la web.",
            price: "AR$ 97,000",
            features: [
                {
                    title: "Sitio estático",
                    description:
                        "Un sitio web estático muestra el mismo contenido a todos los usuarios. Este contenido no cambia a menos que se edite y publique manualmente el código HTML por parte del desarrollador o el administrador del sitio.",
                },
                {
                    title: "Hasta 3 páginas (con 4 secciones)",
                    description: "",
                },
                {
                    title: "Formulario de contacto",
                    description: "",
                },
                {
                    title: "Icono de whatsapp",
                    description: "",
                },
                {
                    title: "Listo en 5 días",
                    description: "",
                },
            ],
            maintenance: {
                title: "Mantén tu sitio web actualizado y seguro con nuestro servicio de mantenimiento de páginas web.",
                features: ["Dominio incluido", "Hosting", "Soporte Técnico"],
                price: "AR$ 15.000 / mes",
            },
        },
        {
            title: "Plan profesional de desarrollo web",
            button: "Contratar plan",
            description:
                "Perfecto para negocios y emprendedores que buscan destacar en línea.",
            price: "AR$ 184.000",
            features: [
                {
                    title: "Sitio dinámico",
                    description:
                        "Contenido interactivo y actualizable: los sitios web dinámicos pueden mostrar contenido diferente a los usuarios. Ofrecen interactividad avanzada, incluyendo la capacidad de permitir a los usuarios crear cuentas, publicar comentarios, y personalizar el contenido o las preferencias. Por ejemplo: tiendas en línea, foros y portales de noticias.",
                },
                {
                    title: "Hasta 5 páginas (con 6 secciones)",
                    description: "",
                },
                {
                    title: "Formulario de contacto",
                    description: "",
                },
                {
                    title: "Icono de whatsapp",
                    description: "",
                },
                {
                    title: "Listo en 5 días",
                    description: "",
                },
            ],
            maintenance: {
                title: "Mantén tu sitio web actualizado y seguro con nuestro servicio de mantenimiento de páginas web.",
                features: ["Dominio incluido", "Hosting", "Soporte Técnico"],
                price: "AR$ 25.000 / mes",
            },
        },
        {
            title: "Plan Plus: Tu solución personalizada para desarrollo web",
            button: "Pedir presupuesto",
            description: "Para proyectos grandes y corporativos",
            price: "",
            features: [
                {
                    title: "Sitio web totalmente personalizado adecuado a tu necesidad",
                    description:
                        "Descubre nuestro exclusivo plan personalizado de desarrollo web. Pagina web a medida, con diseño web profesional, funcionalidades, animaciones y contenido que necesitas para garantizar el éxito de tu sitio en línea.",
                },
            ],
            maintenance: {
                title: "Mantén tu sitio web actualizado y seguro con nuestro servicio de mantenimiento de páginas web.",
                features: [
                    "Dominio incluido",
                    "Hosting",
                    "Soporte Técnico",
                    "Servicio de analítica web",
                ],
                price: "",
            },
        },
    ],
    faqs: {
        title: "Preguntas comunes sobre diseño web y desarrollo web",
        faqs: [
            {
                title: "¿Qué servicios de diseño web ofrecen?",
                description:
                    "En RappidTech ofrecemos una amplia gama de servicios de diseño web, incluyendo diseño UX/UI, desarrollo web responsivo, creación de páginas web personalizadas y más. Nuestro equipo de expertos trabajará contigo para crear un sitio web que refleje la esencia de tu marca y atraiga a tu audiencia.",
            },
            {
                title: "¿Cómo puedo comenzar un proyecto de desarrollo web con ustedes?",
                description:
                    "Iniciar un proyecto de desarrollo web con nosotros es fácil. Simplemente contáctanos y estaremos encantados de discutir tus necesidades y proporcionarte una consulta inicial gratuita.",
            },
            {
                title: "¿Qué ventajas ofrece la automatización de procesos digitales para mi empresa?",
                description:
                    "La automatización de procesos digitales puede ofrecer numerosas ventajas para tu empresa, como la optimización de flujos de trabajo, la reducción de errores, el ahorro de tiempo y recursos, y la mejora de la eficiencia operativa. En RappidTech, te ofrecemos soluciones personalizadas de automatización para adaptarnos a tus necesidades específicas.",
            },
            {
                title: "¿Cuál es el proceso de desarrollo web que siguen?",
                description:
                    "En RappidTech seguimos un proceso de desarrollo web completo y transparente que incluye consultas iniciales, diseño conceptual, desarrollo, pruebas y lanzamiento. Trabajamos estrechamente contigo en cada etapa para garantizar que tus necesidades y objetivos se cumplan con éxito.",
            },
            {
                title: "¿Cómo puedo asegurarme de que mi sitio web sea seguro y protegido contra amenazas cibernéticas?",
                description:
                    "La seguridad de tu sitio web es una prioridad para nosotros. Implementamos las últimas medidas de seguridad, como encriptación de datos, certificados SSL y protección contra malware, para garantizar la seguridad de tu sitio y la información de tus usuarios.",
            },
            {
                title: "¿Ofrecen servicios de mantenimiento y soporte después del lanzamiento de mi sitio web?",
                description:
                    "Sí, en RappidTech ofrecemos servicios de mantenimiento y soporte continuo después del lanzamiento de tu sitio web. Esto incluye actualizaciones regulares, monitoreo de seguridad, soporte técnico y más para garantizar que tu sitio funcione de manera óptima y segura a largo plazo.",
            },
            {
                title: "¿Pueden ayudarme a integrar mi sitio web con otras plataformas o servicios externos?",
                description:
                    "Sí, nuestro equipo de desarrollo web puede ayudarte a integrar tu sitio web con otras plataformas y servicios externos, como sistemas de pago, herramientas de marketing, redes sociales y más. Esto te permite aprovechar al máximo las funcionalidades y características adicionales que ofrecen estas plataformas.",
            },
        ],
    },
};

export default content;
