// Navbar
import logoBlack from "../../static/Vector.png";
import logoWhite from "../../static/Vector-white.png";
import logoDark from "../../static/logo-dark.svg";
import light from "../../static/light-theme.svg";
import dark from "../../static/dark-theme.svg";
import lightHover from "../../static/light-theme-hover.svg";
import darkHover from "../../static/dark-theme-hover.svg";

// Front
import portada from "../../static/portada.png";
import fondoPortada from "../../static/fondo-portada.png";
import fondoPortadaDark from "../../static/fondo-portada-dark.png";

// Cards
import behanceDark from "../../static/behance-dark.png";
import githubDark from "../../static/github-dark.png";
import behanceDarkHover from "../../static/behance-dark-hover.png";
import githubDarkHover from "../../static/github-dark-hover.png";
import behancePink from "../../static/behance.svg";
import githubPink from "../../static/github.svg";
import desarrollo from "../../static/desarrollo-icon.svg";
import trading from "../../static/trading-icon.svg";
import diseño from "../../static/diseño-icon.svg";
import automatizacion from "../../static/automatizacion-icon.svg";
import mockup1 from "../../static/mockup1.png";
import mockup2 from "../../static/mockup2.png";
import mockup3 from "../../static/mockup3.png";

// Team
import teamPicture from "../../static/team.png";
import shape1 from "../../static/shape1.png";
import shape2 from "../../static/shape3.png";
import shape3 from "../../static/shape2.png";

// Social
import linkedin from "../../static/linkedin.png";
import behance from "../../static/behance.png";
import instagram from "../../static/instagram.png";
import tiktok from "../../static/tiktok.png";
import github from "../../static/github.png";
import email from "../../static/email.png";
import whatsapp from "../../static/whatsapp.png";

import linkedinDarkFooter from "../../static/linkedin-dark-footer.png";
import behanceDarkFooter from "../../static/behance-dark-footer.png";
import instagramDarkFooter from "../../static/instagram-dark-footer.png";
import tiktokDarkFooter from "../../static/tiktok-dark-footer.png";
import githubDarkFooter from "../../static/github-dark-footer.png";
import emailDarkFooter from "../../static/email-dark-footer.png";
import whatsappDarkFooter from "../../static/whatsapp-dark-footer.png";

const content = {
    helmet: {
        title: "Desarrollo Web y Diseño de Páginas Web | Soluciones Profesionales en RappidTech",
        description:"Descubre nuestros servicios de Desarrollo Web y Diseño de Páginas Web en RappidTech. Ofrecemos soluciones profesionales y personalizadas para destacar tu marca en línea.",
        canonical: "https://rappidtech.com/",
        language: "es",
        autor: "RappidTech",
        robots: "index, follow",
        publisher: "RappidTech",
    },
    navBar: {
        logo: {
            black: logoBlack,
            white: logoWhite,
            dark: logoDark,
        },
        links: [
            {
                name: "Servicios",
                url: "/#servicios",
                isInternalSection: true,
                subLinks: [
                    {
                        name: "Desarrollo",
                        url: "/desarrollo-web",
                        isInternalSection: false,
                    },
                    {
                        name: "Diseño UX/UI",
                        url: "/diseño-web",
                        isInternalSection: false,
                    },
                    {
                        name: "Trading",
                        url: "https://tradingsolutions.ar/",
                        isExternal: true,
                    },
                    {
                        name: "Automatización",
                        url: "/automatizacion",
                        isInternalSection: false,
                    },
                ],
            },
            // {
            //     name: "Productos",
            //     url: "/#productos",
            //     isInternalSection: true,
            //     subLinks: [
            //         {
            //             name: "Rappid Links",
            //             url: "/rappid-links",
            //             isInternalSection: false,
            //         },
            //     ],
            // },
            { name: "Planes", url: "/planes", isInternalSection: false },
            { name: "Portfolio", url: "/#portfolio", isInternalSection: true },
            { name: "Nosotros", url: "/#nosotros", isInternalSection: true },
            {
                name: "Contacto",
                url: "https://api.whatsapp.com/send?phone=5493816191766&text=Hola%2C%20estoy%20interesado%20en%20sus%20servicios",
                isExternal: true,
            },
        ],
        theme: {
            light: light,
            dark: dark,
            lightHover: lightHover,
            darkHover: darkHover,
        },
    },
    frontPage: {
        title: "En nuestra agencia de diseño, transformamos tus ideas en experiencias digitales impactantes que cautivan a tu audiencia desde el primer clic.",
        paragraph:
            "Con la ayuda de nuestra agencia de diseño web, podrás destacarte con un sitio único que refleje la esencia de tu marca.",
        portada: portada,
        fondoPortada: fondoPortada,
        fondoPortadaDark: fondoPortadaDark,
    },
    titleServicios: "Nuestros servicios de desarrollo de software",
    cardsContent: [
        {
            title: "Desarrollo Web y móvil",
            description:
                "Nos especializamos en el desarrollo web y móvil, ofreciendo aplicaciones y sitios web responsivos y optimizados para una experiencia de usuario excepcional en todos los dispositivos.",
            img: desarrollo,
            textButton: "Explorar",
            url: "/desarrollo-web",
        },
        {
            title: "Diseño UX/UI",
            description:
                "En nuestra agencia, el diseño UX/UI es una prioridad, ya que entendemos la importancia de crear interfaces intuitivas y atractivas que mejoren la experiencia del usuario y maximicen la retención y la conversión.",
            img: diseño,
            textButton: "Explorar",
            url: "/diseño-web",
        },
        {
            title: "Trading",
            description:
                "Con análisis precisos y estrategias sólidas, te brindamos las herramientas para tomar decisiones informadas y maximizar tu potencial en cada movimiento financiero.",
            img: trading,
            textButton: "Explorar",
            url: "https://tradingsolutions.ar/",
        },
        {
            title: "Automatización de procesos",
            description:
                "Optimiza tu flujo de trabajo y libera tu tiempo para lo que realmente importa. Desde tareas repetitivas hasta procesos complejos, transformamos tus procesos en operaciones fluidas y sin interrupciones.",
            img: automatizacion,
            textButton: "Explorar",
            url: "/automatizacion",
        },
    ],
    titleTecnologias:
        "Para la creacion de paginas web creativas, dinamicas y autoadministrables utilizamos las mejores tecnologias:",
    tech: [
        "JavaScript",
        "TypeScript",
        "React",
        "Node.js",
        "Express",
        "HTML",
        "CSS",
        "Bootstrap",
        "Tailwind",
        "Material-UI",
        "GitHub",
        "Firebase",
        "Google Cloud",
        "Wordpress",
        "Figma",
        "PineScript",
        "TradingView",
    ],
    infoCard: {
        title: "Visita nuestra galería de paginas web",
        description:
            "Recorre nuestras paginas web personalizadas. Visita nuestro perfil en Behance para un vistazo detallado de nuestras paginas web a medida.",
        textButtonPortfolio: "Portfolio",
        textButtonGitHub: "GitHub",
        behanceLight: behancePink,
        githubLight: githubPink,
        behanceLightHover: behance,
        githubLightHover: github,
        behanceDark: behanceDark,
        githubDark: githubDark,
        behanceDarkHover: behanceDarkHover,
        githubDarkHover: githubDarkHover,
        urlBehance: "https://www.behance.net/rappidtech",
        urlGitHub: "https://www.github.com/rappidtech",
    },
    Mockups: [mockup1, mockup2, mockup3],
    titleIdeas: "Tu pagina web online soñada hecha realidad",
    chrono: [
        {
            title: "1",
            cardTitle: "Paso 1.",
            cardSubtitle: "Únete a la llamada exploratoria.",
            cardDetailedText:
                "En reunión se plantean las necesidades del cliente y se plantean diferentes enfoques.",
        },
        {
            title: "2",
            cardTitle: "Paso 2.",
            cardSubtitle: "Definición del equipo y planteo de solución.",
            cardDetailedText:
                "Una vez que contamos con toda la información, se plantea una solución y se notifica al equipo de desarrollo.",
        },
        {
            title: "3",
            cardTitle: "Paso 3.",
            cardSubtitle: "Comienzo del desarrollo del proyecto.",
            cardDetailedText:
                "Una vez acordados los plazos, comenzamos a trabajar actualizando cada etapa del proyecto según el feedback del cliente.",
        },
    ],
    testimonials: {
        title: "Voces que validan nuestro compromiso con el trabajo",
        data: [
            {
                name: "Laura Parker",
                country: "EE.UU.",
                testimonial:
                    "El equipo de desarrollo de esta empresa es simplemente excepcional. Me ayudaron a crear una aplicación móvil que superó todas mis expectativas. Su enfoque en el diseño UX/UI hizo que la aplicación fuera intuitiva y hermosa. ¡Los recomendaría a cualquiera!",
            },
            {
                name: "Ricardo Vargas",
                country: "Perú",
                testimonial:
                    "Como trader, necesitaba herramientas personalizadas para analizar el mercado de manera eficiente. Esta empresa no solo creó las soluciones que necesitaba, sino que también me guiaron en cada paso del proceso. Ahora tengo un conjunto de herramientas que me ha ayudado a mejorar mis estrategias de trading.",
            },
            {
                name: "Emily Anderson Smith",
                country: "Canadá",
                testimonial:
                    "La automatización de procesos digitales fue un cambio de juego para mi empresa. Gracias a esta empresa, pudimos eliminar tareas repetitivas y aumentar la eficiencia en nuestras operaciones. ¡El equipo de desarrollo es altamente profesional y comprometido!",
            },
        ],
    },
    team: {
        title: "Somos expertos en paginas web",
        teamPicture: teamPicture,
        data: [
            {
                name: "Maxi",
                position: "Desarrollador web full stack",
            },
            {
                name: "Gonzalo",
                position: "Desarrollador front end",
            },
            {
                name: "Paula",
                position: "Especialista en diseño web",
            },
        ],
        animate: {
            shapes: [shape1, shape2, shape3],
            start: [0, 0, 0],
            end: [320, -150, -150],
        },
        grid: [
            {
                title: "Excelencia operativa",
                description:
                    "Valoramos la excelencia en el desarrollo web y nos esforzamos por superar las expectativas en cada proyecto.",
            },
            {
                title: "Diversidad de soluciones",
                description:
                    "En nuestra agencia de diseño y desarrollo de paginas web, celebramos la diversidad de soluciones, adaptándonos a las necesidades únicas de cada cliente para ofrecer resultados sobresalientes y personalizados.",
            },
            {
                title: "Compromiso",
                description:
                    "En nuestra agencia de creación de páginas web, nos comprometemos a ofrecer soluciones innovadoras y personalizadas que impulsen el éxito en línea de nuestros clientes.",
            },
            {
                title: "Transparencia",
                description:
                    "En nuestra agencia de paginas web, garantizamos una comunicación abierta y transparente en cada proyecto, desde la cotización hasta la entrega final, para que nuestros clientes se sientan seguros y satisfechos con nuestros servicios.",
            },
        ],
    },
    faqs: {
        title: "Preguntas comunes sobre diseño web y desarrollo web",
        faqs: [
            {
                title: "¿Qué servicios de diseño web ofrecen?",
                description:
                    "En RappidTech ofrecemos una amplia gama de servicios de diseño web, incluyendo diseño UX/UI, desarrollo web responsivo, creación de páginas web personalizadas y más. Nuestro equipo de expertos trabajará contigo para crear un sitio web que refleje la esencia de tu marca y atraiga a tu audiencia.",
            },
            {
                title: "¿Cómo puedo comenzar un proyecto de desarrollo web con ustedes?",
                description:
                    "Iniciar un proyecto de desarrollo web con nosotros es fácil. Simplemente contáctanos y estaremos encantados de discutir tus necesidades y proporcionarte una consulta inicial gratuita.",
            },
            {
                title: "¿Qué ventajas ofrece la automatización de procesos digitales para mi empresa?",
                description:
                    "La automatización de procesos digitales puede ofrecer numerosas ventajas para tu empresa, como la optimización de flujos de trabajo, la reducción de errores, el ahorro de tiempo y recursos, y la mejora de la eficiencia operativa. En RappidTech, te ofrecemos soluciones personalizadas de automatización para adaptarnos a tus necesidades específicas.",
            },
            {
                title: "¿Cuál es el proceso de desarrollo web que siguen?",
                description:
                    "En RappidTech seguimos un proceso de desarrollo web completo y transparente que incluye consultas iniciales, diseño conceptual, desarrollo, pruebas y lanzamiento. Trabajamos estrechamente contigo en cada etapa para garantizar que tus necesidades y objetivos se cumplan con éxito.",
            },
            {
                title: "¿Cómo puedo asegurarme de que mi sitio web sea seguro y protegido contra amenazas cibernéticas?",
                description:
                    "La seguridad de tu sitio web es una prioridad para nosotros. Implementamos las últimas medidas de seguridad, como encriptación de datos, certificados SSL y protección contra malware, para garantizar la seguridad de tu sitio y la información de tus usuarios.",
            },
            {
                title: "¿Ofrecen servicios de mantenimiento y soporte después del lanzamiento de mi sitio web?",
                description:
                    "Sí, en RappidTech ofrecemos servicios de mantenimiento y soporte continuo después del lanzamiento de tu sitio web. Esto incluye actualizaciones regulares, monitoreo de seguridad, soporte técnico y más para garantizar que tu sitio funcione de manera óptima y segura a largo plazo.",
            },
            {
                title: "¿Pueden ayudarme a integrar mi sitio web con otras plataformas o servicios externos?",
                description:
                    "Sí, nuestro equipo de desarrollo web puede ayudarte a integrar tu sitio web con otras plataformas y servicios externos, como sistemas de pago, herramientas de marketing, redes sociales y más. Esto te permite aprovechar al máximo las funcionalidades y características adicionales que ofrecen estas plataformas.",
            },
        ],
    },
    form: {
        title: "Hablemos de tu sitio web",
        description:
            "Cuéntanos sobre tu pagina web ideal y te contactaremos para ayudarte. Nos especializamos en crear páginas web profesionales para negocios de todos los tamaños, brindando soluciones a medida que destacan en el competitivo mundo digital.",
        button: "Enviar",
        fields: [
            {
                name: "Nombre",
                placeholder: "Nombre completo",
                type: "text",
                required: true,
            },
            {
                name: "Email",
                placeholder: "Correo electrónico",
                type: "email",
                required: true,
            },
            {
                name: "Teléfono",
                placeholder: "Teléfono",
                type: "tel",
                required: false,
            },
            {
                name: "Mensaje",
                placeholder: "Mensaje",
                type: "textarea",
                required: true,
            },
        ],
    },
    footer: {
        social: [
            {
                url: "https://www.linkedin.com/company/rappidtech",
                icon: linkedin,
            },
            {
                url: "https://www.behance.net/rappidtech",
                icon: behance,
            },
            {
                url: "https://www.instagram.com/rappidtech/",
                icon: instagram,
            },
            {
                url: "https://www.tiktok.com/@rappidtech?_t=8jdIdUnvSLu&_r=1",
                icon: tiktok,
            },
            {
                url: "https://github.com/rappidtech",
                icon: github,
            },
            {
                url: "mailto:nosotros@rappidtech.com",
                icon: email,
            },
            {
                url: "https://api.whatsapp.com/send?phone=5493816191766&text=Hola%2C%20estoy%20interesado%20en%20sus%20servicios%20de%20software",
                icon: whatsapp,
            },
        ],
        socialDark: [
            {
                url: "https://www.linkedin.com/company/rappidtech",
                icon: linkedinDarkFooter,
            },
            {
                url: "https://www.behance.net/rappidtech",
                icon: behanceDarkFooter,
            },
            {
                url: "https://www.instagram.com/rappidtech/",
                icon: instagramDarkFooter,
            },
            {
                url: "https://www.tiktok.com/@rappidtech?_t=8jdIdUnvSLu&_r=1",
                icon: tiktokDarkFooter,
            },
            {
                url: "https://github.com/rappidtech",
                icon: githubDarkFooter,
            },
            {
                url: "mailto:nosotros@rappidtech.com",
                icon: emailDarkFooter,
            },
            {
                url: "https://api.whatsapp.com/send?phone=5493816191766&text=Hola%2C%20estoy%20interesado%20en%20sus%20servicios%20de%20software",
                icon: whatsappDarkFooter,
            },
        ],
        rights: "© 2024 RappidTech. Todos los derechos reservados.",
    },
    error: {
        title: "404",
        subtitle: "Página no encontrada",
        button: "Volver al inicio",
    },
};

export default content;
